import {mapMutations, mapActions, mapGetters} from "vuex";


//sections
import comment from '@/modules/product-card/pages/comments/comment/index.vue'

export default {
    name: "comments",
    components: {
        comment
    },
    data() {
        return {}
    },
    created() {
    },
    mounted() {
        document.title = `${this.currentProduct.title + ' - ' + this.$t('productCard.comments.title')} `
    },
    computed: {
        ...mapGetters({
            currentProduct: 'catalog/currentProduct',
            isAuthenticated: 'auth/isAuthenticated'
        })
    },

    methods: {
        ...mapMutations({
            closeCommentPopup: 'card/SHOW_COMMENT_POPUP',
            clearQuestionId: 'card/SET_QUESTION_ID',
            closeRegisterPopup: 'auth/SHOW_REGISTER_POPUP',

        }),
        ...mapActions({}),
        submit() {
            if (!this.isAuthenticated) {
                this.closeRegisterPopup(true)
            } else {
                this.clearQuestionId('')
                this.closeCommentPopup(true)
            }
        }
    }
}
